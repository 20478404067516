import type { CompanySizeOptionsObject } from "../../translation-keys/company-size-option-keys.js";

const FormCompanySizeOptions: CompanySizeOptionsObject = {
  "1 (GT 10,000 emp)": "従業員数10,000人以上",
  "2 (5,000-10,000 emp)": "従業員数5,000～9,999人",
  "3 (1,000-5,000 emp)": "従業員数1,000～4,999人",
  "4 (500-1,000 emp)": "従業員数500～999人",
  "5 (100-500 emp)": "従業員数100～499人",
  "6 (50-100 emp)": "従業員数50～99人",
  "7 (50-25 emp)": "従業員数25～49人",
  "8 (LT 25 emp)": "従業員数0～24人",
};

export default FormCompanySizeOptions;
